@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?l7wk0d');
  src: url('fonts/icomoon.eot?l7wk0d#iefix') format("embedded-opentype"), url('fonts/icomoon.ttf?l7wk0d') format("truetype"), url('fonts/icomoon.woff?l7wk0d') format("woff"), url('fonts/icomoon.svg?l7wk0d#icomoon') format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-settings:before {
  content: "\e900";
  color: #7ba0a6;
}

.icon-like_line:before {
  content: "\e901";
  color: #7ba0a6;
}

.icon-logout:before {
  content: "\e902";
  color: #7ba0a6;
}

.icon-person:before {
  content: "\e903";
  color: #7ba0a6;
}

.icon-memo:before {
  content: "\e904";
  color: #7ba0a6;
}

.icon-memo-2:before {
  content: "\e905";
  color: #00d5ff;
}

.icon-like_line_2:before {
  content: "\e906";
  color: #f05;
}

.icon-like:before {
  content: "\e907";
  color: #f05;
}

.icon-book:before {
  content: "\e908";
  color: #7ba0a6;
}

.icon-right:before {
  content: "\e909";
  color: #7ba0a6;
}

.icon-up:before {
  content: "\e90a";
  color: #00d5ff;
}

.icon-close:before {
  content: "\e90b";
}

.icon-line {
  .path1:before {
    content: "\e90c";
    color: rgb(0, 185, 0);
  }

  .path2:before {
    content: "\e90d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }

  .path3:before {
    content: "\e90e";
    margin-left: -1em;
    color: rgb(0, 185, 0);
  }

  .path4:before {
    content: "\e90f";
    margin-left: -1em;
    color: rgb(0, 185, 0);
  }

  .path5:before {
    content: "\e910";
    margin-left: -1em;
    color: rgb(0, 185, 0);
  }

  .path6:before {
    content: "\e911";
    margin-left: -1em;
    color: rgb(0, 185, 0);
  }
}

.icon-twitter {
  .path1:before {
    content: "\e912";
    color: rgb(29, 161, 242);
  }

  .path2:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-bookmark {
  .path1:before {
    content: "\e914";
    color: rgb(0, 164, 222);
  }

  .path2:before {
    content: "\e915";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }

  .path3:before {
    content: "\e916";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }

  .path4:before {
    content: "\e917";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-fb {
  .path1:before {
    content: "\e918";
    color: rgb(24, 119, 242);
  }

  .path2:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-pocket {
  .path1:before {
    content: "\e91a";
    color: rgb(239, 65, 84);
  }

  .path2:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-home:before {
  content: "\e91c";
  color: #354b4f;
}

.icon-list:before {
  content: "\e91d";
  color: #7ba0a6;
}

.icon-menu:before {
  content: "\e91e";
  color: #354b4f;
}

.icon-search:before {
  content: "\e91f";
  color: #354b4f;
}

.icon-share:before {
  content: "\e920";
  color: #7ba0a6;
}

.icon-comment:before {
  content: "\e921";
  color: #7ba0a6;
}

.icon-heart:before {
  content: "\e9da";
}
