@charset "utf-8";

@import "icons";

/*
@font-face {
  font-family: 'Noto Sans JP Bold';
  src: url('../fonts/NotoSansJP-Bold.otf') format("opentype");
  font-display: swap;
}
*/

/*
@font-face {
  font-family: 'Noto Sans JP Regular';
  //src: url('../fonts/NotoSansJP-Regular.otf') format("opentype");
  src: url('../fonts/NotoSansJP-Regular.otf') format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans JP Light';
  src: url('../fonts/NotoSansJP-Light.otf') format("opentype");
  font-display: swap;
}
*/

/* reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.movie-wrap {
  position: relative;
  padding-bottom: 70%; /*アスペクト比 16:9の場合の縦幅*/
  height: 0;
  overflow: hidden;
}

.movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider{
  margin: 0px auto;
  width: 80%;

  @media screen and(max-width:468px) {
    width: 100%;
    margin: 0;
  }
}

.slider img{
  height: auto;
  width: 100%;

  @media screen and(max-width: 468px) {
    height: auto;
  }
}
/*slick setting*/
.slick-prev:before,
.slick-next:before {
  color: #000;
}

.l_banners {
  padding-top: 20px;

  ul {
    display: flex;

    @media screen and(max-width:468px) {
      display: block;
    }
  }

  ul li {
    width: 50%;
    position: relative;
    margin-right: 10px;

    @media screen and(max-width:468px) {
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
    }
  }

  ul li:last-child {
    margin-right:  0;
  }
}

.l_experience_banner {
  padding-top: 10px !important;
}

/* reset */

:root {
  //--noto-bold:'Noto Sans JP Bold', sans-serif;
  --noto-bold:'Noto Sans JP', sans-serif;
  //--noto-light:'Noto Sans JP Light', sans-serif;
  --noto-light:'Noto Sans JP', sans-serif;
  //--noto-regular:'Noto Sans JP Regular', sans-serif;
  --noto-regular:'Noto Sans JP', sans-serif;
}

.weight500 {
  font-weight: 700;
}

img {
  max-width: 100%;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: var(--noto-regular);
  font-size: 14px;
  line-height: 1.4;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

@keyframes anime {
  0% {
    transform: scale(0.95);
    opacity: 1;
  }

  90% {
    opacity: 0.1;
  }

  to {
    transform: scale(1.2, 1.5);
    opacity: 0;
  }
}

.l_loading, .l_modal {
  background: rgba(123, 160, 166, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}

.l_loading img {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -25px;
  animation: rotate-anime 3s linear infinite;
}

@keyframes rotate-anime {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.l_modal {
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  opacity: 0;
  transition: all .3s ease-in;

  &.is_show {
    opacity: 1;
  }
}

.l_main_contents {
  padding-top: 80px;
}

.l_wrapper {
  width: 750px;
  margin: 0 auto;

  &.top {
    justify-content: flex-start;
    display: flex;
    width: 80%;

    @media screen and(max-width:768px) {
      width: 100% !important;
      display: block; 
      padding: 0 20px;
    }
  }

  @media screen and(max-width: 768px) {
    width: 100%;
    display: block;
  }
}

@media screen and(max-width: 468px) {
  .slick-dots li button {
    background: #e1ebeb !important; 
  }
}

.l_slider {
  background: #E1EBEB;
  margin-bottom: 80px;

  @media screen and(max-width:468px) {
    margin-bottom: 10px; 
    padding: 0 20px;
    padding-bottom: 20px;
    padding-top: 30px;
  }
}

@media screen and(max-width:468px) {
  .slick-prev, .slick-next {display: none !important;}
  /*
  .slick-prev {left: 0 !important;}
  .slick-next {right: 25px !important;}
  */
}

.l_slider li {
  background: #fff;
  min-height: 250px;
  height: 350px;

  @media screen and(max-width:468px) {
    max-height: 480px;
    min-height: 480px;
  }
}

.l_slider li a {
  text-decoration: none;
}

.l_slider ul.slick-dots li {
  min-height: 20px;
}

.slick-dotted.slick-slider {
  min-height: 350px;
}

.l_slider .p_one_slider_thumbnail {
  width: 50%;
  height: 350px;
  float: left;

  @media screen and(max-width: 768px) {
    width: 100%;
    float: none;
    height: auto;
  }
}

.l_slider .p_one_slider_thumbnail img {
  width: auto;
  height: 350px;
  object-fit: contain;

  @media screen and(max-width:468px) {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }
}

.l_slider .p_one_slider_content {
  width: 50%;
  float: left;
  padding: 40px;

  @media screen and(max-width: 768px) {
    width: 100%;
    float: none;
    padding: 15px;
  }
}

.l_slider .p_one_slider_content .p_one_category_one_article_title {
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 25px;
  line-height: 1.8;
  color: #354b4f;

  @media screen and(max-width:468px) {
    height: 135px;
  }
}

.l_wrapper .l_left {
  width: 64%;

  @media screen and(max-width: 768px) {
    width: 100%;
  }
}

.l_wrapper .l_right {
  width: 30%;
  margin-left: 50px;

  @media screen and(max-width: 768px) {
    width: 100%;
    margin: 0;
  }

}

.l_wrapper_half {
  width: 550px;
}

.l_header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 15px 0;
  z-index: 999;
  border: 1px solid #E1EBEB;
  height: 80px;
  transition: all .3s ease-in;
  opacity: 1;
}

.header_hide {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

.l_header .l_wrapper {
  width: 80%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l_navigation {
  width: 30px;
}

.m_logo {
  width: 30%;
  text-align: center;
  margin-top: 5px;
}

.m_navigation_layout {
  width: 35%;
  text-align: right;
  padding-right: 35px;
  box-sizing: border-box;
}

.p_mypage_icon {
  display: inline-block;
  width: 35px;
}

.p_login_btn {
  color: #7BA0A6;
  text-decoration: none;
  display: inline-block;
  margin-right: 15px;
}

.p_regist_member_btn {
  color: #fff;
  padding: 10px;
  background: linear-gradient(90deg, #FFAA00 0%, #FFC700 100%);
  border-radius: 5px;
  text-decoration: none;
}

.l_gnavi {
  border-top: 1px solid #eee;
  text-align: left;

  li {
    border-bottom: 1px solid #eee;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 15px 10px;
      color: #3C3A3A;

      img {
        margin-right: 8px;
      }
    }
  }
}


/* 体験学習 */
.l_experience_section {
  position: relative;
  margin-top: 40px;
}

.l_experience_wrap{
  width: 100%;
  margin-top: 80px;
}

.l_experience_wrapper{
  width: 750px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;

  @media screen and(max-width: 468px) {
    width: 100%;
    display: block;
  }
}

.l_experience_wrapper .l_left {
  width: 60%;

  @media screen and(max-width: 468px) {
    width: 90%;
    margin: 0 auto;
  }

  .p_one_category_one_article_cat_name {
    padding: 5px;
  }

  .p_one_category_thumbnail {

    @media screen and(max-width:468px) {
      width: 100%;
    }
  }

  .m_one_category_article_right, .m_one_post_layout_right {

    @media screen and(max-width:468px) {
      width: 100%;
    }
  }

  img {
    border-radius: 10px;
  }
}

.l_experience_wrapper .l_right {
  margin-left: 40px;
  width: 35%;

  @media screen and(max-width:468px) {
    width: 90%;
    margin: 0 auto;
  }

  .p_one_category_one_article_cat_name {
    padding: 5px;
  }

  img {
    border-radius: 10px;
  }

  .p_one_category_thumbnail {

    @media screen and(max-width:468px) {
      width: 100%;
    }
  }

  .m_one_category_article_right, .m_one_post_layout_right {

    @media screen and(max-width:468px) {
      width: 100%;
    }
  }
}

.l_experience_contents .l_experience_title {
  width: 100%;
  /* 1px solid #E1EBEB;*/
}

.l_experience_contents h2 {
  width: 750px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 180%;
  color: #354b4f;
  font-family: var(--noto-bold);
  font-weight: 700;
  padding-bottom: 10px;
  text-align: center;

  @media screen and(max-width:468px) {
    width: 90%; 
  }
  
}

.l_experience_contents h2 img {
  width: 40px;
  margin-right: 10px;
  vertical-align: middle;
}

.l_experience_contents .l_experience_tabs {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.l_experience_container {
  width: 100%;
  min-height: 200px;
}

.tabContents {
  //border: 1px solid #E1EBEB;
  display: none;
  padding-top: 30px;

  @media screen and(max-width: 468px) {
    padding: 0;
  }
}

.tabContents ul {
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
}

.tabContents ul li {
  width: 32%;
  /*
  width: 100%;
  float: left;
  */
  //margin-right: 30px;
  margin-bottom: 40px;
  background: #fff;
  margin-right: 15px;

  @media screen and(max-width: 468px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

#eFeature_res li a, .l_left a {
  text-decoration: none;
}

span.icon_detail {
  width: 110px;
  border: 1px solid #ddd;
  border-radius: 15px;
  text-align: center;
  padding: 7px;
  color: #354b4f;
  padding-right: 10px;
}

span.icon_detail:before {
  content: "";
  display: inline-block;
  width: 26px;
  height: 30px;
  vertical-align: middle;
}

span#icon1:before {
  background-image: url(../images/experience/icon1.png);
  background-size: contain;
}
span#icon2:before {
  background-image: url(../images/experience/icon2.png);
  background-size: contain;
}
span#icon3:before {
  background-image: url(../images/experience/icon3.png);
  background-size: contain;
}
span#icon4:before {
  background-image: url(../images/experience/icon4.png);
  background-size: contain;
}
span#icon5:before {
  background-image: url(../images/experience/icon5.png);
  background-size: contain;
}
span#icon6:before {
  background-image: url(../images/experience/icon6.png);
  background-size: contain;
}
span#icon7:before {
  background-image: url(../images/experience/icon7.png);
  background-size: contain;
}

.tabContents .nothing {
  text-align: center;
  font-size: 20px;
  color: #354b4f;
  margin-top: 5%;
}

.tabContents ul li img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 10px;

  @media screen and(max-width:468px) {
    width: 100%;
    float:none; 
  }
}

.tabContents ul li .m_experience_info {
  padding: 10px;

  @media screen and(max-width:468px) {
    width: 100%;
    float: none;
    margin: 0;
  }

  p {
    height: 40px;
    margin-bottom: 10px;
  }
}

.tabContents ul li .post_title {
  color: #7BA0A6;
}

.clear {
  clear:both;
}

.tabContents ul li:nth-child(3n){
  margin-right: 0;
}

.tabContents ul li h4 {
  font-size: 20px;
  line-height: 30px;
  color: #354b4f;
  font-family: var(--noto-bold);
  font-weight: 700;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  //overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.tabContents ul li a {
  text-decoration: none;
}

.tabContents ul li p {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: #354b4f;
}

.tabContents.active {
  display: block;
}

.scroll_tabs_container {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: left;
  height: 100px;
  text-align: center;

  //margin-bottom: 10px;
}

.scroll_tabs_container div.scroll_tab_inner {
  height: 100px;
}

.scroll_tabs_container div.scroll_tab_inner span {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  //background-color: #E1EBEB;
  color: #7BA0A6;
  cursor: pointer;
  border: 1px solid #E1EBEB;
  margin-right: 10px;
  padding-bottom: 10px;
}

.scroll_tabs_container div.scroll_tab_inner span a {
  color: #7BA0A6;
  text-decoration: none;
}

.scroll_tabs_container div.scroll_tab_inner span.active {
  background: #E6FBFF;
  border: 1px solid #00D5FF;
  //border-top: 2px solid #00D5FF;
}

.scroll_tabs_container div.scroll_tab_inner span.active a {
  color: #354B4F; 
}

.scroll_tabs_container div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 0px;
}

.scroll_tabs_container div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 0px;
}

.scroll_tabs_container .scroll_tab_left_button {
  height: 100px;
  background-color: #CCCCCC;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_container .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 100px;
  padding-left: 5px;
}

.scroll_tabs_container .scroll_tab_left_button_over {
  background-color: #999999;
}

.scroll_tabs_container .scroll_tab_left_button_disabled {
  color: #AAAAAA;
  background-color: #CCCCCC;
}

.scroll_tabs_container .scroll_tab_right_button {
  height: 100px;
  background-color: #CCCCCC;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_container .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 100px;
  padding-left: 5px;
}

.scroll_tabs_container .scroll_tab_right_button_over {
  background-color: #999999;
}

.scroll_tabs_container .scroll_tab_right_button_disabled{
  color: #AAAAAA;
  background-color: #CCCCCC;
}

/****************** LIGHT THEME **************************/
.scroll_tabs_theme_light {
  height: 100px;
}

.scroll_tabs_theme_light div.scroll_tab_inner {
  height: 100px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  color: #7BA0A6;
  cursor: pointer;
  width: 140px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first {
  border-left: 0;
  margin-left: 0px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_last {
  margin-right: 0;
  border-right: 0;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 0px;
  background-color: #CCCCCC;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 0px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #CCCCCC;
  border-left: 0px;
}

.scroll_tabs_theme_light .scroll_tab_left_button {
  height: 100px;
  background-color: #CCCCCC;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_light .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 100px;
  padding-left: 5px;
}

.scroll_tabs_theme_light .scroll_tab_left_button_over {
  background-color: #999999;
}

.scroll_tabs_theme_light .scroll_tab_left_button_disabled {
  color: #AAAAAA;
  background-color: #CCCCCC;
}

.scroll_tabs_theme_light .scroll_tab_right_button {
  height: 100px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #CCCCCC;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_light .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 100px;
  padding-left: 5px;
}

.scroll_tabs_theme_light .scroll_tab_right_button_over {
  background-color: #999999;
}

.scroll_tabs_theme_light .scroll_tab_right_button_disabled{
  color: #AAAAAA;
  background-color: #CCCCCC;
}

.scroll_tabs_theme_light div.scroll_tab_inner span.tab_selected {
  //background-color: #AAAAAA;
}

/*.scroll_tabs_theme_light div.scroll_tab_inner span.scroll_tab_first_selected {
  background-color: #AAAAAA;
}*/

/****************** DARK THEME **************************/
.scroll_tabs_theme_dark {
  height: 100px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner {
  height: 100px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 40px;
  font-size: 14px;
  background-color: #333333;
  border-left: 1px solid #222222;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  color: #FFFFFF;
  cursor: pointer;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_first {
  border-left: 0px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_left_finisher {
  padding: 0px;
  width: 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_right_finisher {
  padding: 0px;
  width: 10px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px;
  //border-right: 1px solid #222222;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.scroll_tab_over {
  background-color: #555555;
}

.scroll_tabs_theme_dark .scroll_tab_left_button {
  height: 42px;
  background-color: #333333;
  color: #FFFFFF;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #222222;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_dark .scroll_tab_left_button::before {
  content: "\25C0";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_dark .scroll_tab_left_button_over {
  background-color: #666666;
}

.scroll_tabs_theme_dark .scroll_tab_left_button_disabled {
  color: #444444;
  background-color: #333333;
}

.scroll_tabs_theme_dark .scroll_tab_right_button {
  height: 42px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #333333;
  border: 1px solid #222222;
  color: #FFFFFF;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll_tabs_theme_dark .scroll_tab_right_button::before {
  content: "\25B6";
  line-height: 40px;
  padding-left: 5px;
}

.scroll_tabs_theme_dark .scroll_tab_right_button_over {
  background-color: #666666;
}

.scroll_tabs_theme_dark .scroll_tab_right_button_disabled{
  color: #444444;
  background-color: #333333;
}

.scroll_tabs_theme_dark div.scroll_tab_inner span.tab_selected {
  background-color: #666666;
}

.m_experience_complete {
  background: #fff;
  max-width: 400px;
  //box-shadow: 0 3px 6px rgb(0,0,0,16%);
  border-radius: 3px;
  padding: 20px;
  margin: 50px auto;
  color: #3c3a3a;
  font-size: 12px;
  line-height: 2;
  margin-top: 120px;
}

.m_experience_complete h2 {
  padding: 20px 0 30px;
  text-align: center;
  letter-spacing: 0;
  font-size: 22px;
  line-height: 24px;
  color: #3c3a3a;
}

.m_experience_detail_container {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin-right: -12px;
  margin-left: -12px;
}

.m_experience_detail_container .p_left_item {
  padding: 12px;
  flex: 0 0 50%;
  max-width: 50%;
  object-fit: contain;
  text-align: center;

  @media screen and(max-width: 468px) {
    flex: none;
    max-width: 100%;
    width: 100%;
  }
}

.m_experience_detail_container .p_left_item img {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.m_experience_detail_container .p_right_item {
  padding: 12px;
  max-width: 50%;
  display: block;
  flex-wrap: wrap;
  flex: 1 1 auto;

  @media screen and(max-width: 468px) {
    flex: none;
    max-width: 100%;
    width: 100%;
  }
}

.m_experience_detail h4#serviceName {
  background: #f9fbfb;
  color: #354b4f;
  padding: 10px;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 3px;
  border-left: 5px solid #00D5FF;
}

.m_experience_detail_container .p_right_item h4 {
  font-size: 20px;
  color: #354B4F;
  vertical-align: middle;
  text-align: left;
  font-weight: bolder;

  @media screen and(max-width: 468px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.m_experience_detail {
  @media screen and(max-width:468px) {
    width: 90%;
    margin: 0 auto;
  }
}

.m_experience_detail p.p_service_name {
  //background: #F9FBFB;
  color: #354B4F;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 3px;
}

.m_experience_detail .m_detail_info {
  margin-top: 15px;
}

.m_experience_detail dl {
  //border-top: 1px solid #E1EBEB;
  //margin-top: 15px;
  padding-top: 20px;
}

.m_experience_detail #experience_form dl {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.m_experience_detail dl dt {
  font-weight: var(--ui-font-weight,400);
  font-size: 16px;
  color: #354B4F;
}

.step {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}

.m_experience_detail dl dd {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #354B4F;
}

.m_experience_detail .m_detail_info {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m_experience_detail .m_detail_info dt {
  font-weight: var(--ui-font-weight,400);
  font-size: 16px;
  color: #354B4F;
}

.m_experience_detail .m_detail_info dd {
  margin-top: 10px;
  margin-bottom: 20px;
  white-space:pre-wrap;
  color: #354B4F;
}

.m_experience_detail .m_form_area {
  border: 1px solid #E1EBEB;
  padding: 30px;
}

.m_experience_detail .m_form_area h4 {
  font-weight: bold;
  font-size: 15px;
  color: red;
}

.m_two_column_input, .m_two_column_output {
  margin-top: 10px;
}

.submit_area {
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;

  @media screen and(max-width: 468px) {
    width: 100%;
  }
}

.p_submit_form_btn, .p_edit_submit_form_btn {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  padding: 10px;
  background: -webkit-linear-gradient(left,#fa0 0,#ffc700 100%);
  background: linear-gradient(90deg,#fa0 0,#ffc700 100%);
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #fa0;
  cursor: pointer;

  @media screen and(max-width: 468px) {
    width: 100%;
  }
}

.p_edit_return_form_btn {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  padding: 10px;
  background: -webkit-linear-gradient(leftrgb(207, 207, 207) 0,#5c5c5c 100%);
  background: linear-gradient(90deg, rgb(207, 207, 207) 0,#5c5c5c 100%);
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #5c5c5c;

  @media screen and(max-width: 468px) {
    width: 100%;
  }
}

.m_input_contents_area {
  min-height: 30px;
  display: table;
}

.m_input_contents_area dl {
  border-top: none;
  margin: 0;
  padding: 0;
}

.m_input_contents_area dt {
  background: #d1effd;
  display: table-cell;
  vertical-align: top;
  width: 35%;
  box-sizing: border-box;
  background: #f5f5f5;
  padding: 19px 14px;
  border-bottom: 5px solid #FFF;
  position: relative;

  @media screen and(max-width: 468px) {
    display: flex;
    width: 100%;
  }
}

.m_input_contents_area dt.itemName-input {
  background: #d1effd;
}

.m_input_contents_area dd.itemValue-complete {
  position: relative;
  padding: 18px 80px 18px 20px;
  font-size: 16px;
  line-height: 1;
} 

.m_input_contents_area dd.itemValue-complete span {
  display: inline-block;
  margin-right: 10px; 
}

.m_input_contents_area dd.itemValue-complete a.modifyLink {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  height: 12px;
  margin: auto 0;
  font-size: 12px;
  line-height: 1;
}

.m_input_contents_area dt .require {
  display: inline-block;
  border: solid 1px #ff5c5c;
  border-radius: 3px;
  padding: 2px 5px 0 5px;
  color: #ff5c5c;
  position: absolute;
  right: 13px;
  font-size: 10px;
}

.m_input_contents_area dd {
  background: #f6fcff;
  display: table-cell;
  width: 680px;
  box-sizing: border-box;
  padding: 2px 10px;
  border-bottom: 5px solid #FFF;
  padding-top: 10px;
}

.m_input_contents_area dd .radio {
  padding-top: 20px;
}

.m_input_contents_area dd input[type="text"]{
  border: 1px solid #999;
  border-radius: 3px;
  padding: 8px 10px;
  width: 400px;
  font-size: 14px;
  @media screen and(max-width:468px) {
    height: 40px; 
    margin-top: 10px;
  }
}

.m_input_contents_area dd input[type="radio"]{
  padding: 10px 20px;
}

.m_input_contents_area dd select {
  border: 1px solid #999;
  border-radius: 3px;
  padding: 8px 10px;
  width: 400px;
  font-size: 14px;
  @media screen and(max-width:468px) {
    height: 40px; 
    margin-top: 10px;
  }
}

.m_input_contents_area dd .seimei {
  width: 140px !important;

  @media screen and(max-width: 468px) {
    width: 95% !important;
  }
}

.l_one_sections {
  position: relative;
  padding-top: 50px;

  @media screen and(max-width:468px) {
    //padding: 0 20px;
    padding-top: 80px;
  }
}

.p_one_category_title {
  line-height: 0;
}

.p_one_category_title img {
  width: 100%;
}

.l_about_wrapper .experience_res{
  min-height: 250px;
  margin-top: 30px;

  @media screen and(max-width:468px) {
    margin-bottom: 40px;
  }
}

.l_about_wrapper .experience_res .res_ul li {
  width: 31%;
  float: left;
  margin-right: 30px;

  @media screen and(max-width:468px) {
    width: 100%; 
    float: none;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.l_about_wrapper .experience_res .res_ul li a {
  text-decoration: none;
}

.l_about_wrapper .experience_res .res_ul li img {
  border: 1px solid #E1EBEB;
  border-radius: 3px;
}

.l_about_wrapper .experience_res .res_ul li h4 {
  font-size: 18px;
  color: #354b4f;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.l_about_wrapper .experience_res .res_ul li p {
  color: #354b4f;
}

.l_about_wrapper .experience_res .res_ul li:last-child {
  margin: 0;
}

.p_two_category_title {
  position: relative;
  font-size: 20px;
  color: #354B4F;
  padding: 20px 0;
  border-bottom: 1px solid #E1EBEB;
  border-top: 3px solid #00D5FF;
}

.p_two_category_title img {
  vertical-align: middle;
  padding-right: 10px;
}

.p_one_category_sub_title {
  position: relative;
  font-size: 20px;
  color: #354B4F;
  padding: 20px 0;
  border-bottom: 1px solid #E1EBEB;
}

.p_readmore_category_btn {
  position: absolute;
  border: 1px solid #7BA0A6;
  color: #7BA0A6;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  top: 14px;
  right: 0;
  text-decoration: none;
  transition: all .3s ease-in;

  &:hover {
    background: #7BA0A6;
    color: #fff;
  }
}

.m_one_category_layout {
  margin: 30px 0 50px;

  li {
    width: 34%;
    margin-left: 66%;

    &.-first_layout {
      float: left;
      width: 60%;
      margin-left: 0;
    }
  }
}

.m_two_category_layout {
  margin: 30px 0 0px;

  li {
    width: 100%;
    display: flex;
    padding-bottom: 20px;

    &.-first_layout {
      float: left;
      width: 60%;
      margin-left: 0;
    }
  }
}

.p_new_flag {
  background: #FFAA00;
  border-radius: 5px 0px;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.m_one_category_layout li a {
  text-decoration: none;
  transition: all .3s ease-in;
  display: block;

  &:hover {
    opacity: .5;
  }
}

.m_page_navigation {
  background: #F9FBFB;
  color: #354B4F;

  .l_wrapper {
    width: 750px;
    padding: 10px 0;

    a {
      color: #354B4F;
      text-decoration: none;
    }
  }

  li {
    display: inline-block;
    font-size: 12px;
  }
}

.m_one_category_layout.-category_page li {
  width: 100%;
  float: none;
  margin-left: 0;
  margin-bottom: 30px;

  &.-first_layout {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-bottom: 30px;
  }

  a, &.-first_layout a {
    display: flex;
    justify-content: space-between;
  }
}

.p_one_category_thumbnail {
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.p_two_category_thumbnail {
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.m_two_category_layout .p_two_category_thumbnail {
  width: 35%;
  float: left;
}

.m_two_category_layout .p_two_category_thumbnail img {
  height: 100px;
  width: 100%;
  object-fit: cover;

  @media screen and(max-width: 468px) {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
}

.m_two_category_layout .p_two_category_thumbnail i {
  background: #354B4F;
  color: white;
  border-radius: 3px 0px 0px 0px;
  position: absolute;
  width: 20px;
  text-align: center;
}


.m_two_category_layout .p_two_category_two_article {
  width: 60%;
  margin-left: 10px;
  float: left;
}

.m_two_category_layout a .p_two_category_two_article {
  text-decoration: none;
}

.m_two_category_layout li.-first_layout .p_two_category_two_article {
  width: 100%;
  margin: 0;
}

.m_one_category_layout.-category_page .p_one_category_thumbnail {
  width: 17%;
}

.m_one_category_article_right {
  width: 80%;
}

.m_one_category_layout.-category_page li.-first_layout .p_one_category_thumbnail {
  width: 30%;
}

li.-first_layout .m_one_category_article_right {
  width: 67%;
}

.p_one_category_one_article_title, .p_two_category_two_article_title {
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.8;
  color: #354B4F;
}

.p_one_category_one_article_info {
  font-size: 16px;
  line-height: 1.8;
  color: #354B4F;

  @media screen and(max-width:468px) {
    display: none;
  }
}

.p_two_category_two_article_subtitle {
  color: #7BA0A6;
  padding: 10px 0 20px;
  position: absolute;
  bottom: 0;
}

.p_two_category_two_article_subtitle_second {
  color: #7BA0A6;
  padding: 10px 0 20px;
  position: relative;
  bottom: 0;
}

li.-first_layout .p_one_category_one_article_title, li.-first_layout .p_two_category_two_article_title, .-first .p_one_category_one_article_title {
  font-size: 20px;
}

.p_one_category_one_article_message {
  padding: 10px 0;
  color: #354B4F;
}

.p_one_category_one_article_time, .p_one_category_one_article_cat_name {
  display: inline-block;
  color: #7BA0A6;
  padding: 10px 0 20px;
}

.m_post_three_column_left {
  line-height: 0;
  font-size: 0;

  img {
    width: 100%;
  }
}

.m_post_one_main_information_images img {
  border-radius: 5px;
}

.p_post_one_main_column_stories {
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 16px;
  color: #3C3A3A;

  span {
    font-size: 35px;
  }
}

.m_post_one_main_information {
  margin-top: 10px;
  text-align: left;
  display: block;
  text-decoration: none;
}

.p_post_one_main_information_title {
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 30px;
  line-height: 1.5;
  letter-spacing: 5.4px;
  color: #3C3A3A;
  padding-left: 55px;
  position: relative;
}

.p_post_one_main_information_text {
  letter-spacing: 0.7px;
  color: #3C3A3A;
  padding-top: 20px;
}

.l_footer {
  background: #7BA0A6;
  color: #fff;
  padding: 40px 0 30px;
  margin-top: 120px;
}

.m_footer_half {
  display: flex;
  justify-content: space-between;
}

.m_footer_half_left, .m_footer_half_right {
  width: 50%;
}

.m_footer_half_left {
  text-align: center;
  padding-top: 20px;
  width: 25%;
}

.m_footer_half_right {
  line-height: 1.4;
  width: 70%;
}

.p_footer_contact {
  display: inline-block;
  margin-top: 45px;
  color: #fff;
  text-decoration: none;
  font-family: var(--noto-bold);
  font-weight: 700;
}

.p_copyright {
  font-size: 12px;
  letter-spacing: 0.75px;
  text-align: center;
  padding-top: 15px;
}

.m_footer_under_area {
  text-align: center;
  font-size: 12px;
}

.p_mainvisual_singlepost_title {
  color: #354B4F;
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 28px;
  margin-top: 50px;
  line-height: 1.8;
  padding-bottom: 10px;
}

.m_singlepost_information {
  display: flex;
  padding-bottom: 10px;
}

.m_singlepost_times, .p_singlepost_author {
  width: 50%;
}

.m_singlepost_times {
  text-align: right;

  li {
    font-size: 14px;
    color: #7BA0A6;
    display: inline-block;
    padding-left: 10px;
  }
}

.p_singlepost_author {
  font-size: 14px;
  color: #354B4F;
}

.l_mainvisual_singlepost {
  margin-bottom: 30px;
}

.m_single_post_contents {
  position: relative;

  h2 {
    font-size: 26px;
    color: #3C3A3A;
    line-height: 33px;
    font-family: var(--noto-bold);
    font-weight: 700;
    padding: 15px;
    margin-top: 50px;
    letter-spacing: 3.96px;
    background: #F9FBFB;
    border-left: 5px solid #00D5FF;
    border-radius: 5px;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    color: #354B4F;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.18px;
    margin-top: 30px;
    font-family: var(--noto-bold);
    font-weight: 700;
  }

  h4 {
    color: #171B1F;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.18px;
    margin-top: 30px;
    font-family: var(--noto-bold);
    font-weight: 700;
  }

  p {
    font-size: 15px;
    color: #3C3A3A;
    line-height: 27px;
    letter-spacing: 0.75px;
    padding-top: 30px;
  }

  a {
    color: #7BA0A6;

    > img {
      display: block;
      margin: 30px auto;
      width: 100%;
    }
  }

  em {
    background-color: rgba(255, 232, 10, 0.6);
  }

  ul, ol {
    color: #3C3A3A;
    list-style: disc inside;
    line-height: 1.5;
    margin-top: 30px;
    font-size: 15px;
  }

  ul ul, ol ol {
    margin: 0  0 5px 20px;
  }

  ul li {
    margin-bottom: 5px;
  }

  ol {
    li {
      margin-bottom: 5px;
    }

    list-style-type: decimal;
  }

  blockquote {
    background: #F9FBFB url('../images/bg_blockquote.png') 17px 16px no-repeat;
    color: #354B4F;
    border-radius: 5px;
    padding: 40px 30px 30px 50px;

    p {
      padding: 0 0 10px;
    }

    cite {
      color: #aaa;
      font-size: 12px;
      text-align: right;
    }
  }

  figure {
    font-size: 0;
    line-height: 0;
    width: 80%;
    margin: 50px auto;
    text-align: center;

    /* for youtube */
    position:relative;
    height: 0;
    padding-top: 75%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    /* for images */
    &.wp-block-image {
      height: auto;
      padding-top: 0;
    }
    img {
      max-width: 100%;
      height: 100%;
    }
  }

  figcaption {
    color: #171B1F8A;
    background: #eee;
    border-radius: 0 0 4px 4px;
    padding: 15px;
    font-size: 13px;
    line-height: 24px;
  }

  .wp-block-table {
    font-size: 14px;
    line-height: 1.5;
    height: auto;
    padding-top: 0;

    table {
      width: 100%;

      tr {
        th, td {
          border: 1px solid #aaa;
          padding: 10px;
        }
      }
    }

    &.is-style-stripes table tr:nth-child(2n-1) {
      th, td {
        background: #eee;
      }
    }
  }

  strong {
    font-family: var(--noto-bold);
    font-weight: 700;
  }
}

.m_action_btn_area {
  margin-bottom: 30px;
  position: relative;
  .m_sns_area_list {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.p_add_memo_btn, .p_add_comment_btn {
  display: inline-block;
  border: 1px solid #7BA0A6;
  border-radius: 5px;
  padding: 7px 20px;
  color: #7BA0A6;
  text-decoration: none;
  transition: all .4s ease-in;
  text-align: center;
}

.p_add_memo_btn:hover, .p_add_comment_btn:hover, .p_add_memo_btn.-is_now_favorite, .p_add_comment_btn.-is_now_favorite {
  background: #7BA0A6;
  color: #fff;
}

.p_add_favorite_btn {
  .icon-like {
    display: none;
  }

  &.-is_now_favorite {
    .icon-like {
      display: inline;
    }

    .icon-like_line_2 {
      display: none;
    }
  }
}

.l_comment_contents {
  margin: 40px auto;
  @media screen and (max-width: 782px) {
    margin: 30px auto;
  }
}

.l_one_comment_contents {
  padding: 20px;
  margin-bottom: 20px;
  background: #F9FBFB;
  border: 1px solid #E1EBEB;
  box-sizing: border-box;
  border-radius: 5px;
  color: #354B4F;
}

.p_one_comment_contents_name {
  font-size: 14px;
  font-family: var(--noto-bold);
  font-weight: 700;
  padding-bottom: 5px;
}

.p_one_comment_contents_message {
  line-height: 1.5;
}

.p_one_comment_contents_textarea {
  width: 100%;
  height: 70px;
  resize: none;
  padding: 7px;
  box-sizing: border-box;
}

.p_one_comment_contents_update {
  padding-top: 10px;
  color: #7BA0A6;
  font-size: 12px;
}

.p_one_comment_contents_edit_btns {
  padding-top: 10px;
  text-align: right;

  button {
    border: 0 none;
    padding: 8px 10px;
    margin-left: 10px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: all .3s ease-in;

    &:hover {
      opacity: .6;
    }

    background: #7BA0A6;
  }

  .-edit_btn {
    background: #00D5FF;
  }

  .-delete_btn {
    background: #FFAA00;
  }
}

/* Customize Wordpress */

.p_relative {
  position: relative;
}

.single-post .m_main_visual_area {
  background-position: center center;
  margin-top: 20px;
}

.m_single_page_navigation {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 15px;

  li {
    display: inline-block;
    padding-right: 5px;
    color: #3C3A3A;
    font-size: 12px;
    letter-spacing: 1px;

    a {
      color: #3C3A3A;
      text-decoration: none;
    }
  }
}

.p_single_page_title {
  color: #3C3A3A;
  font-size: 35px;
  line-height: 1.7;
  font-family: var(--noto-bold);
  font-weight: 700;
  padding-bottom: 20px;
}

.p_single_page_category {
  font-size: 12px;
  background: #FFFFFFE6;
  font-family: var(--noto-bold);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0;
  color: #3C3A3A;
  padding: 10px;
  display: inline;
}

.m_single_page_post_contents {
  width: 80%;
  margin: 0 auto;
  line-height: 1.8;

  h2 {
    font-size: 22px;
    font-family: var(--noto-bold);
    font-weight: 700;
    letter-spacing: 3.96px;
    line-height: 30px;
    margin: 20px 0;
  }

  h3 {
    font-size: 18px;
    font-family: var(--noto-bold);
    font-weight: 700;
    background: #F5F6F7;
    color: #171B1F;
    padding: 15px;
    letter-spacing: 0.18px;
    line-height: 30px;
    margin: 20px 0;
  }

  p, ul li {
    letter-spacing: 0.75px;
    color: #3C3A3A;
  }

  p, ul {
    padding-bottom: 10px;
  }

  .has-text-align-right {
    text-align: right;
  }

  .wp-block-image {
    padding-top: 50px;
    line-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: 0 auto;

    img {
      width: 100%;
    }

    figcaption {
      background: #1D21200D;
      border-radius: 0px 0px 4px 4px;
      color: #171B1F8A;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.65px;
      text-align: left;
      padding: 10px;
    }
  }
}

.m_single_post_foot_navigations {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0;

  li {
    width: 60%;

    &:first-child, &:last-child {
      width: 20%;
    }
  }
}

.p_btnarea_read_more {
  display: inline-block;
  background: #3C3A3A;
  vertical-align: middle;
  font-size: 0;
  line-height: 0;
  padding: 10px 10px 10px 0;
  position: relative;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }

  img {
    position: relative;
    z-index: 3;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 120%;
    height: 100%;
    background: #05d2ff;
    transform-origin: right top;
    transform: skewX(-30deg) scale(0, 1);
    transition: transform .3s;
    z-index: 2;
  }

  &:hover::before {
    transform-origin: left top;
    transform: skewX(-30deg) scale(1, 1);
  }
}

.p_login_text a {
  display: block;
  border: 1px solid #00D5FF;
  border-radius: 30px;
  padding: 10px 30px;
  color: #00D5FF;
  text-decoration: none;
  transition: all .5s ease-in;

  &:hover {
    background: #00D5FF;
    color: #fff;
  }
}

.m_comment_submit_area {
  position: relative;
}

.p_comment_message_text {
  width: 75%;
  box-sizing: border-box;
  height: 100px;
  resize: none;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #E1EBEB;
  border-radius: 5px;

  &::placeholder {
    color: #7BA0A6;
    font-family: var(--noto-regular);
  }
}

.p_comment_message_submit_btn {
  width: 22%;
  background: #00D5FF;
  border-radius: 5px;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border: 1px solid #00D5FF;
  color: #fff;
  transition: all .4s ease-in;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    background: transparent;
    color: #00D5FF;
  }
}

.p_cat_list_title {
  margin-top: 20px;
  padding: 5px;
  font-size: 14px;
  font-family: var(--noto-bold);
  font-weight: 700;
  border-bottom: 1px solid #00D5FF;
  letter-spacing: 0.18px;
  line-height: 1.3;
}

.m_cat_list {
  line-height: 2;

  a {
    display: block;
    border-bottom: 1px dotted #ccc;
    padding: 5px;
    text-decoration: none;
    color: #3C3A3A;
    transition: all .3s ease-in;

    &:hover {
      background: #fafafa;
    }
  }
}

.l_alert {
  position: fixed;
  text-align: center;
  transition: all .3s ease-in;
  background: #354B4F;
  bottom: -60px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  z-index: 99999;
  border-radius: 5px;
  left: 50%;
  padding: 15px 20px;
  transform: translateX(-50%);
  opacity: 0;

  &.slideIn {
    bottom: 90px;
    opacity: 1;
  }
}

.m_searchform_layout {
  width: 35%;

  &.is-pc {
    @media screen and (max-width: 782px) {
      display: none;
    }
  }

  &.is-sp {
    padding: 30px;
    width: 100%;
    @media screen and (min-width: 781px) {
      display: none;
    }
  }
}

.p_search_box {
  background: #F9FBFB;
  padding: 8px 15px 8px 35px;
  border: 0 none;
  border-radius: 5px;

  @media screen and (max-width: 782px) {
    padding: 16px 15px 16px 35px;
  }
}

.m_hamburger_menu_trigger {
  position: relative;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 14px;
  right: 0;
  cursor: pointer;
  transition: all .3s ease-in;

  .p_border1, .p_border2, .p_border3 {
    border-top: 2px solid #555;
    border-radius: 2px;
    position: absolute;
    top: 5px;
    left: 2.5px;
    width: 18px;
    transition: all .3s ease-in;
  }

  .p_border2 {
    top: 10px;
  }

  .p_border3 {
    top: 15px;
  }

  &.is_active {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);

    .p_border1 {
      -webkit-transform: translateY(20px) rotate(-45deg);
      transform: translateY(20px) rotate(-45deg);
      top: -9px;
    }

    .p_border2 {
      -webkit-transform: translateY(0) rotate(45deg);
      transform: translateY(0) rotate(45deg);
    }

    .p_border3 {
      opacity: 0;
    }
  }
}

.m_hamburger_menu {
  position: fixed;
  top: 0;
  left: 100%;
  transition: left .3s ease-in;
  z-index: 98;
  background: rgba(123, 160, 166, 0.5);
  width: 100%;

  &.is_active {
    left: 0;
  }
}

.m_hamburger_menu_inner {
  width: 30%;
  margin-left: 70%;
  background: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
}

.m_left_contents_menu {
  border-left: 0 none;
  border-right: 0 none;

  li a {
    padding: 0 10px;
  }
}

.m_hamburger_menu_profile {
  padding: 15px 10px;
  line-height: 0;
  font-size: 0;

  img {
    width: 40px;
  }

  .p_login_text a {
    font-size: 14px;
    font-family: var(--noto-bold);
    font-weight: 700;
    text-align: center;
    padding: 20px 30px;
  }
}

.p_category_title {
  font-size: 30px;
  font-family: var(--noto-bold);
  font-weight: 700;
  padding: 10px 15px;
  color: #3C3A3A;
  width: 60%;
  margin: 20px auto 30px;
  border-left: 5px solid #00D5FF;
}

.m_single_page_sns_share_list {
  position: fixed;
  top: 150px;
  left: calc(45% - 500px);
  z-index: 99;

  li {
    list-style: none;

    a {
      text-decoration: none;
      font-size: 30px;
    }
  }
}

.m_single_page_experience {
  position: fixed;
  top: 330px;
  right: calc(40% - 500px);
  z-index: 99;

  img {
    width: 230px !important;
  }
}

ul.m_single_page_sns_share_list {
  margin-top: 0;
}

.m_single_page_sns_share_list {
  transition: all .3s ease-in;
  opacity: 1;

  &.-isClear {
    opacity: 0;
    top: 50px;
  }

  &.is_pc {
    display: block;
  }

  &.is_sp {
    display: none;
  }
}

.m_single_post_contents {
  .yarpp-related {
    background: #f1f1f1;
    padding: 15px;
    margin: 0;

    h3 {
      color: #171b1f;
      font-size: 15px;
      border: 0 none;
    }

    ol {
      margin-top: 5px;
      list-style: none;

      a {
        font-family: var(--noto-regular);
        color: #47a7bf;
        text-decoration: underline;
        transition: all .3s ease-in;

        &:hover {
          opacity: .6;
        }
      }
    }

    p {
      padding-top: 0;
    }
  }

  .yarpp-related-none {
    display: none;
  }
}

.rtoc-mokuji-content {
  margin: 30px 0 60px;
  padding: 30px !important;
  max-width: 100%;

  @media screen and(max-width: 768px) {
    padding: 20px !important;
  }

  &.frame2 {
    &::before {
      border: none;
      border-radius: 5px;
      opacity: 1;
    }
  }

  #rtoc-mokuji-title {
    font-family: var(--noto-bold);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.8;

    @media screen and(max-width: 768px) {
      font-size: 16px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      padding-left: 16px;
    }

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      background-image: url('/images/icon_index.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
    }
  }

  .rtoc_open_close {
    display: none;
  }

  .rtoc-mokuji {
    margin-top: 10px !important;

    @media screen and(max-width: 768px) {
      margin-top: 12px !important;
    }
  }

  .mokuji_ol > .rtoc-item {
    padding: 0 !important;

    &:not(:nth-of-type(1)) {
      padding-top: 8px !important;
    }

    &:nth-of-type(1) {
      &::before {
        top: 0 !important;
      }
    }

    &::before {
      top: 8px !important;
    }

    a {
      font-family: var(--noto-regular);
      text-decoration: underline;
    }
  }
}

#toc_container {
  display: block !important;
  background: #f1f1f1;
  border: 0 none;
  padding: 15px;
  margin: 30px 0 0;
  font-size: 100%;

  p.toc_title {
    text-align: left;
  }

  a {
    color: #3c3a3a;
    font-family: var(--noto-regular);
  }

  .toc_number {
    display: none;
  }

  p.toc_title + ul.toc_list {
    margin: 10px 0 0 5px;
  }

  ul {
    list-style: disc inside;
  }

  &.no_bullets {
    ul, li, ul li {
      list-style: disc inside;
    }
  }

  li {
    margin-top: 5px;
  }
}

.l_memo_contents {
  background: #E6FBFF;
  border-radius: 5px;
  padding: 30px;
  margin-top: 30px;
}

.p_comment_contents_title {
  font-size: 20px;
  color: #354B4F;
  font-family: var(--noto-bold);
  font-weight: 700;
  margin: 60px 0 10px;

  

  .icon-comment {
    padding-right: 10px;
  }
}

.p_memo_contents_title {
  font-size: 20px;
  color: #354B4F;
  font-weight: 700;
  font-family: var(--noto-bold);

  .icon-memo-2 {
    padding-right: 15px;
  }
}

.l_memo_contents {
  .m_comment_submit_area {
    margin-top: 20px;
  }

  .p_comment_message_text {
    border: 0 none;

    &::placeholder {
      line-height: 1.7;
    }
  }
}

.p_memo_delete_btn {
  color: #7BA0A6;
  font-size: 14px;
  text-decoration: none;
  transition: all .3s ease-in;
  position: absolute;
  top: 55px;
  left: 78%;

  &:hover {
    opacity: .6;
  }

  .icon-close {
    padding-right: 2px;
  }
}

.l_sns_contents {
  border-top: 1px solid #e1ebeb;
  border-bottom: 1px solid #e1ebeb;
  padding: 20px 0;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m_sns_favorite_btn {
  a {
    font-size: 16px;
    color: #f05;
    text-decoration: underline;
    transition: all .3s ease-in;
    text-decoration: none;
    line-height: 26px;

    &:hover {
      opacity: .6;
    }

    .icon-like, .icon-like_line_2 {
      padding-right: 15px;
    }
  }

  &.-is_now_favorite .icon-like_line_2, .icon-like {
    display: none;
  }

  &.-is_now_favorite .icon-like {
    display: inline;
  }
}

.m_sns_area_list {
  line-height: 26px;

  li {
    display: inline-block;
    color: #7BA0A6;
    font-size: 26px;
    padding-left: 15px;

    a {
      text-decoration: none;
      transition: all .3s ease-in;

      &:hover {
        opacity: .6;
      }
    }
  }

  .p_sns_share_text {
    font-size: 16px;
    vertical-align: top;
  }

  li .icon-share {
    padding-right: 15px;
  }
}

.m_hamburger_mypage_area {
  > a {
    display: flex;
    color: #7BA0A6;
    align-items: center;
    padding: 20px;
    position: relative;
    text-decoration: none;
  }

  p {
    padding-left: 15px;
  }

  .icon-right {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -10px;
    font-size: 20px;
    line-height: 1;
  }
}

.p_gnavi_cat_title {
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 20px;
  line-height: 180%;
  color: #354B4F;
  text-align: left;
  padding: 20px 10px 10px 10px;
}

.m_go_to_top {
  position: fixed;
  right: calc( 45% - 400px );
  bottom: 50px;
}

.m_searchform {
  position: relative;
}

.icon-search {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 20px;

  @media screen and (max-width: 782px) {
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
  }
}

.m_modal_login_user {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p_modal_title {
  color: #354B4F;
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
}

.p_modal_message {
  padding-top: 10px;
  text-align: center;
}

.p_modal_btn_area {
  padding-top: 20px;
  text-align: center;
}

.p_modal_close_btn, .p_regist_member_btn, .p_modal_memo_delete {
  padding: 10px 20px;
  text-align: center;
  margin: 0 10px;
}

.p_modal_close_btn {
  border: 1px solid #7BA0A6;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  color: #7BA0A6;
  padding: 9px 19px;
  cursor: pointer;
  transition: all .3s ease-in;
}

.p_modal_memo_delete {
  border: 1px solid #7BA0A6;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  color: #7BA0A6;
  padding: 9px 19px;
  cursor: pointer;
  transition: all .3s ease-in;
  background: #00D5FF;
  border: 1px solid #00D5FF;
  color: #fff;
}

.p_modal_close_btn:hover, .p_modal_memo_delete:hover {
  opacity: .6;
}

.no_scroll {
  overflow: hidden;
}

.is_sp, .is_hide {
  display: none;
}

.m_sitemap_column {
  margin-bottom: 40px;

  h2 {
    color: #354B4F;
    font-size: 22px;
    padding-left: 8px;
    border-left: solid 3px #00D5FF;
    margin-bottom: 24px;
  }
}

.m_sitemap_column_list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.p_sitemap_list_item {
  flex: 1 1 33%;
  font-size: 16px;
  margin-bottom: 18px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  a:hover {
    opacity: .5;
    transition: ease .2s;
  }
}

.p_new_registration_banner {
  display: block;

  img {
    display: block;
    margin: auto;
  }
}

@media screen and (max-width: 910px) {
  .l_header .l_wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 782px) {
  .l_wrapper {
    width: 100%;
    padding: 0 20px;
  }

  .l_header {
    padding: 15px;

    .l_wrapper {
      width: 100%;
      padding: 0;
    }
  }

  .m_logo {
    width: 45%;
  }

  .m_navigation_layout {
    width: 53%;
    font-size: 12px;
    padding-right: 25px;
  }

  .p_regist_member_btn .is_sp {
    display: inline;
  }

  .m_hamburger_menu_trigger {
    top: 8px;
  }

  .p_login_btn {
    margin-right: 7px;
  }

  .m_page_navigation .l_wrapper {
    width: 100%;
    padding: 10px 15px;
    overflow: auto;
    white-space: nowrap;
  }

  .l_one_sections {
    margin-top: 0;
  }

  .m_one_category_layout, .m_two_category_layout {
    margin-bottom: 20px;

    li {
      width: 100%;
      margin-left: 0;

      &.-first_layout {
        float: none;
        width: 100%;
      }

      a {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
      }

      &.-first_layout a {
        display: block;
      }
    }
  }

  .p_one_category_thumbnail {
    width: 35%;
  }

  .m_one_category_layout, .m_two_category_layout {
    &.-category_page .p_one_category_thumbnail {
      width: 35%;
    }

    li.-first_layout .p_one_category_thumbnail, li.-first_layout .p_two_category_thumbnail {
      width: 100%;
    }
  }

  .m_one_post_layout_right, .m_one_category_article_right {
    width: 60%;
  }

  .m_one_category_layout li.-first_layout .m_one_post_layout_right {
    width: 100%;
  }

  .p_readmore_category_btn {
    width: 100%;
    position: static;
    text-align: center;
    margin-bottom: 40px;
  }

  .m_one_category_layout.-category_page li.-first_layout {
    a {
      display: block;
    }

    .p_one_category_thumbnail {
      width: 100%;
    }
  }

  li.-first_layout .m_one_category_article_right {
    width: 100%;
  }

  .p_mainvisual_singlepost_title {
    margin-top: 30px;
    font-size: 20px;
  }

  .m_singlepost_information {
    display: block;
  }

  .m_singlepost_times, .p_singlepost_author {
    width: 100%;
  }

  .m_singlepost_times {
    text-align: left;
    margin-top: 5px;

    li {
      padding-left: 0;
      padding-right: 10px;
      font-size: 12px;
    }
  }

  .p_add_favorite_btn, .p_add_memo_btn, .p_add_comment_btn {
    width: 49%;
  }

  .p_add_favorite_btn {
    margin-right: 1%;
  }

  .l_footer {
    margin-top: 50px;
    padding-top: 0;
  }

  .m_footer_half {
    display: block;
  }

  .m_footer_half_left, .m_footer_half_right {
    width: 100%;
  }

  .m_footer_half_left {
    padding-top: 50px;
    width: 50%;
    margin: 0 auto;
  }

  .m_footer_half_right {
    padding-top: 20px;
  }

  .l_mainvisual_singlepost {
    display: block;
    margin: 0;
  }

  .p_single_title, .p_single_text, .p_single_subtitle {
    padding-bottom: 15px;
  }

  .m_single_image_area {
    width: 100%;
    margin: 10px 0 20px;
  }

  .m_single_post_contents {
    max-width: 100%;

    h2 {
      margin-top: 30px;
    }

    h3 {
      margin-top: 20px;
    }

    h4 {
      margin-top: 15px;
    }

    p {
      padding-top: 15px;
    }

    figure {
      width: 100%;
      margin: 30px 0;
    }
  }

  .m_hamburger_menu_inner {
    width: 70%;
    margin-left: 30%;
  }

  .m_single_post_contents_outer {
    display: block;
  }

  .m_single_post_contents, .m_single_post_contents_right, .l_one_comment_contents, .m_comment_submit_area {
    width: 100%;
  }

  .p_category_title {
    width: 100%;
    margin: 15px 10px 20px;
    padding: 0 15px;
  }

  .m_single_page_sns_share_list {
    width: 100%;
    position: fixed;
    top: auto;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.8);

    &.-isClear {
      opacity: 0;
      top: auto;
      bottom: -65px;
    }

    &.is_pc {
      display: none;
    }

    &.is_sp {
      display: flex;
    }
  }

  ul.m_single_page_sns_share_list li {
    margin-bottom: 0;
  }

  .m_single_page_sns_share_list li {
    display: flex;
    align-items: center;

    a {
      font-size: 30px;
      padding: 10px;
      display: block;
    }
  }

  li .p_bottom_menu_button.p_regist_member_btn {
    color: white;
    border-radius: 30px;
    font-size: 18px;
  }

  .l_memo_contents {
    margin: 20px 20px 0;
    padding: 20px;
    width: auto;
  }

  .p_memo_contents_title {
    font-size: 16px;
  }

  .l_memo_contents .m_comment_submit_area {
    margin-top: 15px;
  }

  .p_comment_message_text {
    width: 100%;
    height: 120px;
  }

  .p_comment_message_submit_btn {
    position: static;
    width: 100%;
    margin-top: 15px;
  }

  .l_sns_contents {
    display: block;
    margin: 30px 20px;
    width: auto;
  }

  .m_sns_favorite_btn {
    text-align: center;
    padding: 15px 0;
  }

  .m_sns_area_list {
    text-align: center;
    padding-bottom: 15px;

    .p_sns_share_text {
      display: none;
    }
  }

  .m_modal_login_user {
    width: 90%;
  }

  .p_memo_delete_btn {
    position: static;
    display: block;
    padding-top: 10px;
    text-align: right;
  }

  .p_regist_member_btn {
    padding: 10px 8px;
    margin: 0;
  }

  .p_comment_contents_title {
    margin-top: 30px;
  }

  .m_action_btn_area {
    margin-bottom: 10px;
    .m_sns_area_list {
      position: static;
      margin-top: 15px;
    }
  }

  .is_sp {
    display: block;
  }

  .is_pc {
    display: none;
  }
}

/* about page */

.l_about_wrapper {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
}

.l_about_mainvisual {
  margin-top: 80px;
  background: url('../images/about/bg_mainvisual.png') center center no-repeat #F9FBFB;
  min-height: 700px;
  text-align: center;
  margin-bottom: 20px;
}

.p_about_page_title {
  color: #FFAA00;
  font-size: 32px;
  line-height: 1.8;
  font-family: var(--noto-bold);
  font-weight: 700;
  padding-top: 90px;
}

.p_about_page_sub_title {
  font-size: 20px;
  line-height: 1.8;
  font-weight: 700;
  font-family: var(--noto-bold);
  color: #354B4F;
  padding-top: 10px;
}

.p_about_page_message {
  font-size: 16px;
  line-height: 1.8;
  color: #354B4F;
  padding-top: 10px;
}

.p_about_cv_btn {
  color: #fff;
  padding: 15px 30px;
  background: linear-gradient(90deg, #FFAA00 0%, #FFC700 100%);
  border-radius: 30px;
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  margin-top: 40px;
  display: inline-block;
  position: relative;
  transition: .2s;
  z-index: 1;

  &::before, &::after {
    content: "";
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 30px;
    background: linear-gradient(90deg, #FFAA00 0%, #FFC700 100%);
    transform: translate3d(0, 0, 0);
  }

  &::before {
    animation: anime 2s ease-out infinite;
  }

  &::after {
    animation: anime 2s ease-out 1s infinite;
  }
}

.l_about_message_area {
  font-size: 16px;
  padding: 100px;
}

.p_about_message_title {
  text-align: center;
  padding-bottom: 30px;
}

.p_about_message_text {
  color: #354B4F;
  line-height: 1.8;
}

.l_about_ability_area {
  background: #E6FBFF;
  border-radius: 10px;
  padding: 60px 65px;
}

.p_about_ability_title {
  font-size: 24px;
  line-height: 180%;
  text-align: center;
  color: #354B4F;
  font-family: var(--noto-bold);
  font-weight: 700;
  margin-bottom: 20px;
}

.m_about_ability_layout {
  display: flex;
  flex-wrap: wrap;
}

.m_about_ability_one_contents {
  padding-top: 30px;
  width: 49%;
  margin-right: 1%;
  box-sizing: border-box;
  padding-left: 120px;
  position: relative;

  &:nth-child(2n) {
    margin-right: 0;
    margin-left: 1%;
  }
}

.p_about_ability_one_icon {
  position: absolute;
  top: 30px;
  left: 0;
}

.p_about_ability_one_title {
  font-size: 20px;
  line-height: 30px;
  color: #354B4F;
  font-weight: 700;
  font-family: var(--noto-bold);
  padding-bottom: 10px;
}

.p_about_ability_one_message {
  line-height: 1.5;
  color: #354B4F;
}

.l_about_contents {
  padding: 100px 0;
}

.p_about_contents_title {
  font-size: 24px;
  text-align: center;
  color: #354B4F;
  font-family: var(--noto-bold);
  font-weight:700;
  padding-bottom: 30px;
}

.m_about_layout {
  display: flex;
}

.m_about_contents_one_layout {
  width: 28%;
  margin: 2.5%;
}

.p_about_contents_one_icon {
  text-align: center;
}

.p_about_contents_one_title {
  text-align: center;
  font-family: var(--noto-bold);
  font-weight: 700;
  font-size: 20px;
  line-height: 1.8;
  color: #354B4F;
  padding: 20px 0 15px;
}

.p_about_contents_one_message {
  font-size: 16px;
  line-height: 1.8;
  color: #354B4F;
}

.l_about_footer {
  background: #F9FBFB;
  padding: 70px 0 60px;
  margin-bottom: -120px;
}

.p_about_footer_message {
  font-size: 16px;
  line-height: 1.8;
  color: #354B4F;
}

.m_about_footer_contents {
  text-align: center;
}

.p_about_footer_message {
  padding-top: 15px;

  a {
    font-family: var(--noto-bold);
    font-weight: 700;
    text-decoration: underline;
    color: #354B4F;

    &:hover {
      text-decoration: none;
    }
  }
}

.m_about_footer_contents .p_about_cv_btn {
  margin-top: 0;
}

.m_related_articles {
  margin-top: 20px;
  padding: 40px 20px;
  background-color: #F9FBFB;

  ol {
    margin-top: 20px;
  }
}

.p_related_articles_title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  img {
    margin-right: 8px;
  }
}

.p_related_article {
  margin-bottom: 16px;

  a {
    display: flex;
  }

  img {
    max-width: 120px;
    margin-right: 12px;
    border-radius: 8px;
  }
}

.p_new_article_thumbnail {
  position: relative;
}

.p_new_article_label {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 0 8px 0;
  background-color: #FFAA00;
  color: white;
  padding: 6px;
  font-size: 12px;
}

.p_related_article_title {
  font-size: 16px;
  color: #354B4F;
  margin-bottom: 4px;
}

.p_related_article_info {
  display: flex;
  font-size: 14px;
  color: #7BA0A6;
}

.p_related_article_date::after {
  content: '|';
  padding: 0 4px;
}

.p_related_article_category + .p_related_article_category {
  margin-left: 8px;
}

@media screen and (max-width: 782px) {
  .l_about_mainvisual {
    background: url('../images/about/bg_mainvisual_sp.png') center bottom no-repeat;
    padding: 0 20px;
  }

  .p_about_page_title {
    padding-top: 40px;
  }

  .l_about_wrapper {
    padding: 0 20px;
  }

  .l_about_message_area, .l_about_ability_area, .l_about_contents, .l_about_footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .m_about_ability_layout, .m_about_layout {
    display: block;
  }

  .m_about_ability_one_contents, .m_about_contents_one_layout {
    width: 100%;
    margin: 0;
    padding-top: 20px;
  }

  .m_about_ability_one_contents {
    &:nth-child(2n) {
      width: 100%;
      margin: 0;
      padding-top: 20px;
    }

    min-height: 120px;
  }

  .l_about_footer {
    margin-bottom: -50px;
  }
}

.l_popular_posts_page {
  .p_post_page_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
    padding-bottom: 18px;
    border-bottom: 1px solid #E1EBEB;

    span {
      font-family: var(--noto-bold);
      font-weight:700;
      font-size: 20px;
      line-height: 1.8;
    }
  }

  .p_popular_list {
    li > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
    }
    li:nth-of-type(1) {
      > a {
        @media screen and(max-width: 768px) {
          display: block;
        }
      }
      img {
        width: 40%;

        @media screen and(max-width: 768px) {
          width: 100%;
        }
      }

      .p_popular_item_inner {
        width: 60%;
        @media screen and(max-width: 768px) {
          padding-top: 12px;
          padding-left: 0;
          width: 100%;
        }
      }

      .p_popular_item_title {
        font-size: 20px;

        @media screen and(max-width: 768px) {
          font-size: 16px;
        }
      }

      .p_popular_item_description {
        font-size: 16px;
      }
    }

    li:not(:nth-of-type(1)) {
      margin-top: 30px;
    }

    img {
      width: 20%;
      border-radius: 5px;
      overflow: hidden;

      @media screen and(max-width: 768px) {
        width: 30%;
      }
    }

    .p_popular_item_inner {
      padding-left: 20px;
      width: 80%;

      @media screen and(max-width: 768px) {
        width: 70%;
      }
    }

    .p_popular_item_title {
      font-family: var(--noto-bold);
      font-weight: 700;
      font-size: 16px;
      line-height: 1.8;
      color: #354B4F;
    }

    .p_popular_item_description {
      font-family: var(--noto-regular);
      font-size: 14px;
      line-height: 1.8;
      color: #354B4F;

      @media screen and(max-width: 768px) {
        display: none;
      }
    }

    .p_popular_item_date {
      font-family: var(--noto-regular);
      font-size: 14px;
      line-height: 1.8;
      color: #7BA0A6;

      @media screen and(max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}

.l_edv_career {
  margin-top: 30px;
  a {
    display: block;
    text-align: center;
    img { display: inline; }
    .is_sp { display: none; }
    @media screen and(max-width: 768px) {
      .is_pc { display: none; }
      .is_sp { display: inline; }
    }
  }
}

.l_related_post_container {
  margin-top: 30px;
  padding: 30px 0;
  background-color: #F9FBFB;

  @media screen and(max-width: 768px) {
    padding: 30px 0 47px;
  }

  h2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      padding-left: 16px;
      font-family: var(--noto-bold);
      font-weight: 700;
      font-size: 20px;
      line-height: 1.8;
    }
  }

  li {
    margin-top: 30px;

    &:nth-of-type(1) {
      @media screen and(max-width: 768px) {
        margin-top: 18px;
      }
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
    }

    img {
      width: 20%;

      @media screen and(max-width: 768px) {
        width: 36%;
      }
    }

    .p_single_related_item_inner {
      padding-left: 20px;
      width: 80%;

      @media screen and(max-width: 768px) {
        width: 64%;
      }
    }

    .p_single_related_item_title {
      font-family: var(--noto-bold);
      font-weight: 700;
      font-size: 16px;
      line-height: 1.8;
      color: #354B4F;
    }
    .p_single_related_item_description {
      display: inline-block;
      vertical-align: middle;
      font-size: 0;

      span {
        font-family: var(--noto-regular);
        font-size: 14px;
        line-height: 1.8;
        color: #7BA0A6;
      }

      span:nth-of-type(2),
      span:nth-of-type(3) {
        @media screen and(max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.l_tag_contents {
  margin-top: 100px;
  a {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #00d5ff;
    border-radius: 5px;
    transition: all .4s ease-in;
    color: #00d5ff;
    text-decoration: none;
    margin-right: 3px;
    &:hover {
      background: #00d5ff;
      color: #fff;
    }
  }
}
@media screen and (max-width: 782px) {
  .l_tag_contents {
    margin-top: 60px;
  }
}
